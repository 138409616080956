body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f9fafc;
}

* {
  box-sizing: border-box;
}

.no-focus:focus {
  outline: none;
  box-shadow: none;
}

/* elrond dapp tools overwrites - there is no other way at the moment and I don't want to use react-bootstrap */
.card-body {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.card-body h4.mb-4,
.card-body h4.mb-3 {
  font-size: 24px;
  margin-top: 0;
}

.card-body h4.mb-3 {
  margin-bottom: 10px;
}

.card-body h4.mb-4,
.card-body h4.mb-3,
.card-body p.lead.mb-0,
.card-body p.mb-4,
.card-body a.btn.btn-primary {
  text-align: center;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.card-body .btn.btn-primary {
  margin: 10px;
  padding: 10px;
  display: -webkit-inline-flex;
  background: #fff;
  border: 1px solid #c1c4d6;
  border-radius: 4px;
  cursor: pointer;
}

.card-body a.btn.btn-primary {
  color: inherit;
  font-size: 13.3333px;
  text-decoration: none;
}

.state.m-auto.p-4.text-center {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* elrond dapp tools overwrites end */
